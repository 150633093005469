import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { ScrollRestoration } from './scrollRestoration'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { FullScreenScrollRestoration } from './fullScreenScrollRestoration'

export const site: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.AppDidMountHandler).to(FullScreenScrollRestoration)
}

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageDidUnmountHandler).to(ScrollRestoration)
}

export const editor = site
